import Therapeutic_Massage_img from '../images/services_images/Massage/Therapeutic_Massage_img.jpg'
import Relaxing_Massage_img from '../images/services_images/Massage/Relaxing_Massage_img.jpg'
import Lymphatic_Massage_img from '../images/services_images/Massage/Lymphatic_Massage_img.jpg'
import Air_Cup_Massage_img from '../images/services_images/Massage/Air_Cup_Massage_img.jpg'
import Pregnant_Massage_img from '../images/services_images/Massage/Pregnant_Massage_img.jpg'
import Hot_Stone_Massage from '../images/services_images/Massage/Hot_Stone_Massage.jpg'
import Thai_Massage_img from '../images/services_images/Massage/Thai_Massage_img.jpg'
import Swedish_Massage_img from '../images/services_images/Massage/Swedish_Massage_img.jpg'

import Moroccan_Bath_img from '../images/services_images/BathAndBody/Moroccan_Bath_img.jpg'
import Royal_Moroccan_Bath_img from '../images/services_images/BathAndBody/Royal_Moroccan_Bath_img.jpg'
import Body_Wax_img from '../images/services_images/BathAndBody/Body_Wax_img.jpg'
import Hand_and_Leg_Wax_img from '../images/services_images/BathAndBody/Hand_and_Leg_Wax_img.jpg'
import Underwarm_Wax_img from '../images/services_images/BathAndBody/Underwarm_Wax_img.jpg'

import hairscrup from '../images/services_images/Hair_Treatment/hairscrup.jpg'
import hairmask from '../images/services_images/Hair_Treatment/hairmask.jpg'
import oilTreatment from '../images/services_images/Hair_Treatment/oilTreatment.jpg'
import HairTrim from '../images/services_images/Hair_Treatment/HairTrim.jpg'
import hairscrupAndoilTreatment from '../images/services_images/Hair_Treatment/hairscrupAndoilTreatment.jpg'
// import hairscrupAndoilTreatment_3d from '../images/services_images/Hair_Treatment/hairscrupAndoilTreatment_3d.jpg'

import paraffin from '../images/services_images/Nails/paraffin.jpg'
import handColor from '../images/services_images/Nails/handColor.jpg'
import footSpa from '../images/services_images/Nails/footSpa.jpg'
import footSpa2 from '../images/services_images/Nails/footSpa2.jpg'
import Pedicure from '../images/services_images/Nails/Pedicure.jpg'
import FrenchManicure from '../images/services_images/Nails/FrenchManicure.jpg'
import French_Gel_Polish from '../images/services_images/Nails/French_Gel_Polish.jpg'

import Skin_Cleansing from '../images/services_images/Skin_Care/Skin_Cleansing.jpg'
import Skin_Cleansing_with_mask from '../images/services_images/Skin_Care/Skin_Cleansing_with_mask.jpg'
// import Skin_Cleansing_with_mask2 from '../images/services_images/Skin_Care/Skin_Cleansing_with_mask2.jpg'
import Hydrafacial_Skin_Cleansing from '../images/services_images/Skin_Care/Hydrafacial_Skin_Cleansing.jpg'
import Anti_Aging_Skin_Cleanser from '../images/services_images/Skin_Care/Anti_Aging_Skin_Cleanser.jpg'
import Dyeing_and_Bleaching from '../images/services_images/Skin_Care/Dyeing_and_Bleaching.jpg'
// import Dyeing_and_Bleaching2 from '../images/services_images/Skin_Care/Dyeing_and_Bleaching2.jpg'
// import Dyeing_and_Bleaching3 from '../images/services_images/Skin_Care/Dyeing_and_Bleaching3.jpg'

import twitter_Icon from '../images/twitter_Icon.svg'
import instagram_Icon from '../images/instagram_Icon.svg'
import tiktok_Icon from '../images/tiktok_Icon.svg'
import whatsapp_Icon from '../images/whatsapp_Icon.svg'
import phone_Icon from '../images/phone_Icon.svg'

const AllCakesItems = [

    {
        id: '01',
        categoryNameAR: 'خدمات المساج',
        categoryNameEN: 'Massage',
        categoryItems: [
            {
                id: "01",
                img: Therapeutic_Massage_img,
                itemNameAR: "مساج علاجي",
                itemNameEN: "Therapeutic Massage",
                descriptionAR: "يخفف مساج العلاج الطبيعي الآلام والتوتر، ويعيد التوازن للجسم من خلال حركات دقيقة مخصصة لتقليل التعب وتحسين الحركة.",
                descriptionEN: "This therapeutic massage relieves pain and tension, restoring balance with precise movements designed to reduce fatigue and improve mobility.",
                duration: "45",
                price: "150"
            },
            {
                id: "02",
                img: Relaxing_Massage_img,
                itemNameAR: "مساج استرخائي",
                itemNameEN: "Relaxing Massage",
                descriptionAR: "يعمل مساج الاسترخاء على تهدئة العقل والجسم من خلال تقنيات تدليك خفيفة تساعد على تخفيف التوتر وتمنحك شعوراً بالراحة.",
                descriptionEN: "A relaxing massage that soothes the mind and body through gentle techniques, helping to relieve stress and providing a sense of calm and comfort.",
                duration: "45",
                price: "130"
            },
            {
                id: "03",
                img: Lymphatic_Massage_img,
                itemNameAR: "مساج لمفاوي",
                itemNameEN: "Lymphatic Massage",
                descriptionAR: "يحفز مساج التصريف اللمفاوي الدورة اللمفاوية لتحسين التصريف وتقليل الانتفاخ، مما يعزز الشعور بالصحة والحيوية.",
                descriptionEN: "The lymphatic massage stimulates lymph circulation to improve drainage and reduce swelling, promoting a feeling of health and vitality.",
                duration: "45",
                price: "250"
            },
            {
                id: "04",
                img: Air_Cup_Massage_img,
                itemNameAR: "مساج كاسات هوائية",
                itemNameEN: "Air Cup Massage",
                descriptionAR: "يعزز مساج الأكواب الهوائية الدورة الدموية ويخفف التوتر العضلي، مما يساهم في تحسين الاسترخاء وتخفيف الألم.",
                descriptionEN: "The air cup massage enhances blood circulation and relieves muscle tension, promoting relaxation and easing discomfort.",
                duration: "45",
                price: "170"
            },
            {
                id: "05",
                img: Pregnant_Massage_img,
                itemNameAR: "مساج حوامل",
                itemNameEN: "Pregnant Massage",
                descriptionAR: "يخفف مساج الحمل من آلام العضلات ويمنح الراحة، بلمسات لطيفة تناسب الأم والجنين لزيادة الاسترخاء وتخفيف التوتر.",
                descriptionEN: "This pregnancy massage eases muscle pain with gentle touches for both mother and baby, promoting relaxation and relieving stress.",
                duration: "45",
                price: "200"
            },
            {
                id: "06",
                img: Hot_Stone_Massage,
                itemNameAR: "مساج أحجار ساخنة",
                itemNameEN: "Hot Stone Massage",
                descriptionAR: "تستخدم الحجارة الساخنة لتخفيف التوتر العضلي العميق وزيادة تدفق الدم، مما يعزز الشعور بالراحة والاسترخاء العميق.",
                descriptionEN: "Hot stones are used to relieve deep muscle tension and increase blood flow, enhancing comfort and promoting deep relaxation.",
                duration: "45",
                price: "160"
            },
            {
                id: "07",
                img: Thai_Massage_img,
                itemNameAR: "مساج تايلندي",
                itemNameEN: "Thai Massage",
                descriptionAR: "يستخدم مساج تايلاندي تقنيات تمدد الجسم والتلاعب بالعضلات لتعزيز المرونة وتقليل التوتر، مما يحقق توازناً بدنياً.",
                descriptionEN: "Thai massage uses stretching techniques and muscle manipulation to enhance flexibility and reduce tension, achieving physical balance.",
                duration: "45",
                price: "170"
            },
            {
                id: "08",
                img: Swedish_Massage_img,
                itemNameAR: "مساج سويدي",
                itemNameEN: "Swedish Massage",
                descriptionAR: "يجمع مساج السويدي بين تقنيات التدليك الخفيف والعميق لتحسين الدورة الدموية وتخفيف التوتر، مما يوفر شعوراً بالاسترخاء.",
                descriptionEN: "Swedish massage combines light and deep techniques to improve circulation and relieve tension, providing a sense of relaxation.",
                duration: "45",
                price: "170"
            }
        ]
    },
    {
        id: '02',
        categoryNameAR: 'الحمام والجسم',
        categoryNameEN: 'Bath & Body',
        categoryItems: [
            {
                id: "01",
                img: Moroccan_Bath_img,
                itemNameAR: "حمام مغربي",
                itemNameEN: "Moroccan Bath",
                descriptionAR: "حمام مغربي ينظف البشرة بعمق ويزيل السموم، باستخدام تقنيات تقليدية تمنحك شعوراً بالاسترخاء والانتعاش.",
                descriptionEN: "The Moroccan bath deeply cleanses the skin and detoxifies, using traditional techniques that leave you feeling relaxed and refreshed.",
                duration: "45",
                price: "150"
            },
            {
                id: "02",
                img: Royal_Moroccan_Bath_img,
                itemNameAR: "حمام مغربي ملكي",
                itemNameEN: "Royal Moroccan Bath",
                descriptionAR: "حمام مغربي ملكي يجمع بين التنظيف العميق والترطيب، ليمنحك تجربة فاخرة مع لمسات دافئة لراحة مثلى.",
                descriptionEN: "The royal Moroccan bath combines deep cleansing and hydration, offering a luxurious experience with warm touches for ultimate comfort.",
                duration: "45",
                price: "200"
            },
            {
                id: "03",
                img: Body_Wax_img,
                itemNameAR: "واكس جسم",
                itemNameEN: "Body Wax",
                descriptionAR: "يتم إزالة الشعر الزائد بلطف باستخدام الشمع، مما يوفر لكِ بشرة ناعمة وخالية من الشعر لفترة طويلة.",
                descriptionEN: "Excess hair is gently removed with wax, leaving your skin smooth and hair-free for an extended period.",
                duration: "45",
                price: "250"
            },
            {
                id: "04",
                img: Hand_and_Leg_Wax_img,
                itemNameAR: "واكس يد ورجل",
                itemNameEN: "Hand & Leg Wax",
                descriptionAR: "تتم إزالة الشعر من اليدين والساقين باستخدام الشمع، للحصول على بشرة ناعمة وحريرية تدوم طويلاً.",
                descriptionEN: "Hair is removed from hands and legs using wax, resulting in smooth, silky skin that lasts for a long time.",
                duration: "45",
                price: "100"
            },
            {
                id: "05",
                img: Underwarm_Wax_img,
                itemNameAR: "واكس اندر ارم",
                itemNameEN: "Underarm Wax",
                descriptionAR: "تتم إزالة الشعر من منطقة الإبطين بلطف، مما يضمن بشرة ناعمة ونظيفة خالية من الشعر لفترة أطول.",
                descriptionEN: "Hair is gently removed from the underarm area, ensuring smooth, clean skin that remains hair-free for longer.",
                duration: "45",
                price: "70"
            },
            {
                id: "06",
                img: Underwarm_Wax_img,
                itemNameAR: "دلكة جسم",
                itemNameEN: "Body Dalka",
                descriptionAR: "دلك الجسم يساعد على تنشيط الدورة الدموية، حيث يتم تدليك الجسم بالكامل باستخدام مكونات طبيعية تزيل الشوائب وتجدد البشرة.",
                descriptionEN: "Body Dalka helps stimulate blood circulation with a full-body massage using natural ingredients, removing impurities and leaving the skin soft and glowing.",
                duration: "45",
                price: "250"
            },
            {
                id: "07",
                img: Underwarm_Wax_img,
                itemNameAR: "دلكة يد ورجل",
                itemNameEN: "Hand & foot Dalka",
                descriptionAR: "دلّك اليدين والقدمين يركز على إزالة الجلد الميت وترطيب البشرة، مما يمنح يديكِ وقدميكِ إحساساً بالنظافة والنعومة والراحة.",
                descriptionEN: "Hand & Foot Dalka focuses on exfoliating dead skin and moisturizing, giving your hands and feet a clean, soft, and refreshed feeling.",
                duration: "45",
                price: "180"
            }
        ]
    },
    {
        id: '03',
        categoryNameAR: 'خدمات الشعر',
        categoryNameEN: 'Hair Treatment',
        categoryItems: [
            {
                id: "01",
                img: hairscrup,
                itemNameAR: "سكراب شعر",
                itemNameEN: "Hair Scrub",
                descriptionAR: "يعمل مقشر الشعر على إزالة الشوائب والدهون، مما يمنح شعرك نظافة عميقة ويعزز نموه الصحي.",
                descriptionEN: "The hair scrub removes impurities and oil, giving your hair a deep clean and promoting healthy growth.",
                duration: "45",
                price: "80"
            },
            {
                id: "02",
                img: hairmask,
                itemNameAR: "ماسك شعر",
                itemNameEN: "Hair Mask",
                descriptionAR: "قناع الشعر يرطب الشعر بعمق ويعزز لمعانه، مما يساعد على استعادة الحيوية ويجعل الشعر أكثر نعومة.",
                descriptionEN: "The hair mask deeply moisturizes and enhances shine, helping to restore vitality and making hair softer.",
                duration: "45",
                price: "50"
            },
            {
                id: "03",
                img: oilTreatment,
                itemNameAR: "حمام زيت",
                itemNameEN: "Oil Treatment",
                descriptionAR: "علاج الزيت يرطب الشعر بعمق ويعزز صحته من الجذور حتى الأطراف، مما يحسين ملمس الشعر ويمنحه لمعاناً يلفت الأنظار.",
                descriptionEN: "The oil treatment moisturizes hair and boosts its health, improving texture and adding beautiful shine.",
                duration: "45",
                price: "50"
            },
            {
                id: "04",
                img: HairTrim,
                itemNameAR: "قص أطراف",
                itemNameEN: "Hair Trim",
                descriptionAR: "تقليم الشعر يعزز شكل تسريحتك ويزيل الأطراف المتقصفة، مما يمنح شعرك مظهراً صحياً ومتجدداً.",
                descriptionEN: "A hair trim enhances your hairstyle and removes split ends, giving your hair a healthy and refreshed look.",
                duration: "45",
                price: "50"
            },
            {
                id: "05",
                img: hairscrupAndoilTreatment,
                itemNameAR: "سكراب + حمام زيت",
                itemNameEN: "Scrup + Oil Treatment",
                descriptionAR: "مزيج من مقشر الشعر وعلاج الزيت يمنح فروة رأسكِ تنظيفاً عميقاً ويعمل على ترطيب الشعر بشكل ممتاز، ليتركه لامعاً ومفعماً بالحيوية.",
                descriptionEN: "A combination of hair scrub and oil treatment ensures deep cleaning and excellent hydration for healthy, shiny hair.",
                duration: "45",
                price: "150"
            }
        ]
    },
    {
        id: '04',
        categoryNameAR: 'خدمات الأظافر',
        categoryNameEN: 'Nails',
        categoryItems: [
            {
                id: "01",
                img: paraffin,
                itemNameAR: "البرافين",
                itemNameEN: "Paraffin",
                descriptionAR: "علاج البرافين يغذي ويرطب بشرتكِ بعمق، مما يساعد على تنعيم اليدين والقدمين وتخفيف الجفاف، ليمنحك إحساساً ناعماً ومريحاً.",
                descriptionEN: "Paraffin treatment deeply softens hands and feet, moisturizing the skin and leaving it feeling smooth.",
                duration: "45",
                price: "70"
            },
            {
                id: "02",
                img: handColor,
                itemNameAR: "لون يد",
                itemNameEN: "Hand Color",
                descriptionAR: "تطبيق لون الأظافر على اليدين يعزز من جاذبيتكِ، حيث يوفر لكِ خيارات متنوعة من الألوان الجميلة التي تناسب كل إطلالاتكِ.",
                descriptionEN: "Nail color application on hands gives you a stylish look with vibrant colors that complement your appearance.",
                duration: "45",
                price: "20"
            },
            {
                id: "03",
                img: footSpa,
                itemNameAR: "لون رجل",
                itemNameEN: "Foot Color",
                descriptionAR: "تطبيق لون الأظافر على القدمين يمنحكِ مظهراً أنيقاً ومتكاملاً، بألوان متعددة تتماشى مع ذوقكِ الشخصي وإطلالتكِ اليومية.",
                descriptionEN: "Nail color application on feet provides an elegant look with colors that match your style.",
                duration: "45",
                price: "20"
            },
            {
                id: "04",
                img: footSpa2,
                itemNameAR: "تركيب أظافر مع لون",
                itemNameEN: "Nails With Color",
                descriptionAR: "تطبيق لون الأظافر على اليدين والقدمين يمنحكِ مظهراً متناسقاً وكاملاً، بألوان تضفي لمسة جمال متكاملة على إطلالتك.",
                descriptionEN: "Nail color application on both hands and feet, giving you a complete and coordinated look.",
                duration: "45",
                price: "100"
            },
            {
                id: "05",
                img: handColor,
                itemNameAR: "سبا يد",
                itemNameEN: "Hand Spa",
                descriptionAR: "سبا اليدين يوفر ترطيباً عميقاً لبشرتكِ ويعالج الجفاف، ليمنح يديكِ نعومة مريحة وإحساساً بالانتعاش.",
                descriptionEN: "A hand spa enhances skin softness and treats dryness, leaving your hands feeling refreshed and comfortable.",
                duration: "45",
                price: "30"
            },
            {
                id: "06",
                img: footSpa,
                itemNameAR: "سبا رجل",
                itemNameEN: "Foot Spa",
                descriptionAR: "سبا القدمين يخفف من الإرهاق وينعش بشرتكِ، حيث يوفر ترطيباً عميقاً وراحة لقدمين متعبتين تحتاجان للاسترخاء.",
                descriptionEN: "A foot spa refreshes the skin and relieves fatigue, deeply moisturizing and soothing your feet.",
                duration: "45",
                price: "30"
            },
            {
                id: "07",
                img: Pedicure,
                itemNameAR: "البدكير",
                itemNameEN: "Pedicure",
                descriptionAR: "الباديكير يعتني بصحة وجمال قدميكِ وأظافركِ، مما يمنحكِ تنظيفاً وترطيباً كاملاً لإطلالة جميلة ومنعشة.",
                descriptionEN: "A pedicure cares for the health of your nails and feet, cleaning, moisturizing, and giving them a beautiful look.",
                duration: "45",
                price: "170"
            },
            {
                id: "08",
                img: FrenchManicure,
                itemNameAR: "فرنش",
                itemNameEN: "French Manicure",
                descriptionAR: "المناكير الفرنسي يمنح يديكِ مظهراً أنيقاً وبسيطاً مع أطراف بيضاء نظيفة تبرز جاذبيتكِ وإطلالتكِ المميزة.",
                descriptionEN: "The French manicure features clean white tips, giving your hands a sophisticated and simple look.",
                duration: "45",
                price: "70"
            },
            {
                id: "09",
                img: French_Gel_Polish,
                itemNameAR: "طلاء جل فرنش",
                itemNameEN: "French Gel Polish",
                descriptionAR: "تطبيق جل فرنسي يتميز بالثبات الطويل، حيث يجمع بين الأناقة والمتانة لإطلالة مثالية تدوم طويلاً.",
                descriptionEN: "A long-lasting French gel polish that combines elegance with durability for a perfect look.",
                duration: "45",
                price: "250"
            }
        ]
    },
    {
        id: '05',
        categoryNameAR: 'تنظيف البشرة',
        categoryNameEN: 'Skin Care',
        categoryItems: [
            {
                id: "01",
                img: Skin_Cleansing,
                itemNameAR: "تنظيف بشرة",
                itemNameEN: "Skin Cleansing",
                descriptionAR: "تنظيف البشرة يزيل الأوساخ والزيوت المتراكمة من المسام، مما ينعش البشرة ويتركها نظيفة، مشرقة، ومتوازنة بشكل مثالي.",
                descriptionEN: "Skin cleansing removes dirt and oils, refreshing the skin and leaving it clean and glowing.",
                duration: "45",
                price: "160"
            },
            {
                id: "02",
                img: Skin_Cleansing_with_mask,
                itemNameAR: "تنظيف مع ماسك",
                itemNameEN: "Skin Cleansing With Mask",
                descriptionAR: "تنظيف البشرة مع قناع يغذي البشرة بعمق، ويعزز نعومتها وإشراقتها، ويمنحها الترطيب اللازم لتبدو أكثر حيوية.",
                descriptionEN: "Skin cleansing with a mask enhances smoothness and radiance, providing deep hydration.",
                duration: "45",
                price: "180"
            },
            {
                id: "03",
                img: Hydrafacial_Skin_Cleansing,
                itemNameAR: "فتلة الوجه",
                itemNameEN: "Face Threading",
                descriptionAR: "تقدم خدمة فتلة الوجه عناية فائقة للبشرة من خلال إزالة الشعر الزائد بدقة وفعالية، مما يمنحك بشرة ناعمة ونقية.",
                descriptionEN: "This service provides superior skin care by removing excess hair precisely, giving you smooth skin.",
                duration: "45",
                price: "100"
            },
            {
                id: "04",
                img: Anti_Aging_Skin_Cleanser,
                itemNameAR: "فتلة الشنب",
                itemNameEN: "Upper Lip Threading",
                descriptionAR: "تتميز خدمة فتلة الشنب بالدقة والعناية لإزالة الشعر الزائد من منطقة الشنب، مما يساعد على تحسين مظهر الوجه.",
                descriptionEN: "this service is characterized by precision and care to remove excess hair from the mustache area.",
                duration: "45",
                price: "60"
            },
            {
                id: "05",
                img: Dyeing_and_Bleaching,
                itemNameAR: "صبغة حواجب وتشقير",
                itemNameEN: "Dyeing and bleaching",
                descriptionAR: "صبغ وتبييض الشعر يغير لونه بشكل أنيق، ليمنحك مظهراً متجدداً يناسب شخصيتك وإطلالتك بشكل مميز.",
                descriptionEN: "Hair dyeing and bleaching for a fresh color and renewed look that matches your style.",
                duration: "45",
                price: "50"
            }
        ]
    },


]

const SocialMediaIcons = [
    {
        icon: instagram_Icon,
        link: "https://www.instagram.com/lumina_spa1"
    },
    {
        icon: twitter_Icon,
        link: "https://twitter.com/lumina_spa1"
    },
    {
        icon: tiktok_Icon,
        link: "https://www.tiktok.com/@lumina_spa1"
    },
    {
        icon: whatsapp_Icon,
        link: "https://wa.me/966570075668"
    },
    {
        icon: phone_Icon,
        link: "tel:+966570075668"
    },
]

const couponCodes = [
    { name: 'Ra4195', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "لأنك جاية من طرف روان", },
    { name: 'As11', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "لأنك جاية من طرف أحمد", },
    { name: 'N247', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "لأنك جاية من طرف ", },
    { name: 'MD4176', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "لأنك جاية من طرف محمد", },
    { name: 'A5734', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "خصم خاص من لومينا سبا 💜", },
    { name: 'SH_Cakery30', percentage: 30, minmumPrice: 250, DisplayedTextEN: "", DisplayedTextAR: "خصم خاص من شهد كيكري 💜", },
    { name: 'SH_Cakery20', percentage: 20, minmumPrice: 170, DisplayedTextEN: "", DisplayedTextAR: "خصم خاص من شهد كيكري 💜", },

];

// Neighborhood and delivery fee logic
const neighborhoods =
    [
        {
            id: "01",
            nameEN: "Safa",
            nameAR: "الصفا",
            fee: 20
        },
        {
            id: "02",
            nameEN: "Marwah",
            nameAR: "المروة",
            fee: 25
        },
        {
            id: "03",
            nameEN: "Ar Rabwa",
            nameAR: "الربوة",
            fee: 30
        },
        {
            id: "04",
            nameEN: "Al Nuzha",
            nameAR: "النزهة",
            fee: 25
        },
        {
            id: "05",
            nameEN: "Al Naeem",
            nameAR: "النعيم",
            fee: 35
        },
        {
            id: "06",
            nameEN: "Al Salamah",
            nameAR: "السلامة",
            fee: 30
        },
        {
            id: "07",
            nameEN: "Al Mohammadiyyah",
            nameAR: "المحمدية",
            fee: 35
        },
        {
            id: "08",
            nameEN: "Al Murjan",
            nameAR: "المرجان",
            fee: 40
        },
        {
            id: "09",
            nameEN: "Bryman",
            nameAR: "بريمان",
            fee: 30
        },
        {
            id: "10",
            nameEN: "Al Hamadaniyyah",
            nameAR: "الحمدانية",
            fee: 35
        },
        {
            id: "11",
            nameEN: "Al Kauthar",
            nameAR: "الكوثر",
            fee: 35
        },
        {
            id: "12",
            nameEN: "Al-Salehiyah",
            nameAR: "الصالحية",
            fee: 40
        },
        {
            id: "13",
            nameEN: "Al Falah",
            nameAR: "الفلاح",
            fee: 40
        },
        {
            id: "14",
            nameEN: "Al Rahmanyah",
            nameAR: "الرحمانية",
            fee: 40
        },
        {
            id: "15",
            nameEN: "Al Bashaer",
            nameAR: "البشاير",
            fee: 45
        },
        {
            id: "16",
            nameEN: "Taiba",
            nameAR: "طيبة",
            fee: 40
        },
        {
            id: "17",
            nameEN: "Al Firdous",
            nameAR: "الفردوس",
            fee: 50
        },
        {
            id: "18",
            nameEN: "Al Yaqoot",
            nameAR: "الياقوت",
            fee: 50
        },
        {
            id: "19",
            nameEN: "Al Zummrad",
            nameAR: "الزمرد",
            fee: 55
        },
        {
            id: "20",
            nameEN: "Al Riyadh",
            nameAR: "الرياض",
            fee: 45
        },
        {
            id: "21",
            nameEN: "Al Faisaliyyah",
            nameAR: "الفيصلية",
            fee: 25
        },
        {
            id: "22",
            nameEN: "Al Samer",
            nameAR: "السامر",
            fee: 25
        },
        {
            id: "23",
            nameEN: "Al Ajwad",
            nameAR: "الأجواد",
            fee: 25
        },
        {
            id: "24",
            nameEN: "Al Montazah",
            nameAR: "المنتزه",
            fee: 30
        },
        {
            id: "25",
            nameEN: "Al Tawfiq",
            nameAR: "التوفيق",
            fee: 30
        },
        {
            id: "26",
            nameEN: "Al Waha",
            nameAR: "الواحة",
            fee: 30
        },
        {
            id: "27",
            nameEN: "Al-Rehab",
            nameAR: "الرحاب",
            fee: 25
        },
        {
            id: "28",
            nameEN: "Al Taiseer",
            nameAR: "التيسير",
            fee: 30
        },
        {
            id: "29",
            nameEN: "Mishrifah",
            nameAR: "مشرفة",
            fee: 30
        },
        {
            id: "30",
            nameEN: "Al Sharafeyah",
            nameAR: "الشرفية",
            fee: 30
        },
        {
            id: "31",
            nameEN: "Al Andalus",
            nameAR: "الأندلس",
            fee: 35
        },
        {
            id: "32",
            nameEN: "Al-Ruwais",
            nameAR: "الرويس",
            fee: 30
        },
        {
            id: "33",
            nameEN: "An Nahdah",
            nameAR: "النهضة",
            fee: 35
        },
        {
            id: "34",
            nameEN: "Bani Malik",
            nameAR: "بني مالك",
            fee: 30
        },
        {
            id: "35",
            nameEN: "An Naseem",
            nameAR: "النسيم",
            fee: 30
        },
        {
            id: "36",
            nameEN: "Al Warood",
            nameAR: "الورود",
            fee: 30
        },
        {
            id: "37",
            nameEN: "Al Sulaymaniyah",
            nameAR: "السليمانية",
            fee: 30
        },
        {
            id: "38",
            nameEN: "Al Fayha'a",
            nameAR: "الفيحاء",
            fee: 35
        },
        {
            id: "39",
            nameEN: "Abruq Ar Rughamah",
            nameAR: "أبرق الرغامة",
            fee: 35
        },
        {
            id: "40",
            nameEN: "Al-Balad",
            nameAR: "البلد",
            fee: 35
        },
        {
            id: "41",
            nameEN: "Al Jami`ah",
            nameAR: "الجامعة",
            fee: 35
        },
        {
            id: "42",
            nameEN: "Quwaizah",
            nameAR: "قويزة",
            fee: 35
        },
        {
            id: "43",
            nameEN: "Mada'en Al-Fahd",
            nameAR: "مدائن الفهد",
            fee: 35
        },
        {
            id: "44",
            nameEN: "Al-Nazlah Al-Yamaniyah",
            nameAR: "النزهة اليمانية",
            fee: 35
        },
        {
            id: "45",
            nameEN: "Al Mahjar",
            nameAR: "المهجر",
            fee: 40
        },
        {
            id: "46",
            nameEN: "Al-Rawabi",
            nameAR: "الروابي",
            fee: 35
        },
        {
            id: "47",
            nameEN: "Al Muntazahat",
            nameAR: "المنتزهات",
            fee: 35
        },
        {
            id: "48",
            nameEN: "Al Adel",
            nameAR: "العدل",
            fee: 35
        },
        {
            id: "49",
            nameEN: "Alfadel",
            nameAR: "الفضل",
            fee: 40
        },
        {
            id: "50",
            nameEN: "Al-Wazeeriyah",
            nameAR: "الوزيرية",
            fee: 35
        },
        {
            id: "51",
            nameEN: "Al Jawharah",
            nameAR: "الجوهرة",
            fee: 35
        },
        {
            id: "52",
            nameEN: "Alajaweed",
            nameAR: "الأجاويد",
            fee: 40
        },
        {
            id: "53",
            nameEN: "Al Sanabel",
            nameAR: "السنابل",
            fee: 40
        },
        {
            id: "54",
            nameEN: "Almahameed",
            nameAR: "المحاميد",
            fee: 45
        }
    ];

export { SocialMediaIcons, AllCakesItems, couponCodes, neighborhoods }